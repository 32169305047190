<template>
  <div class="products">
    <div class="product-body">
      <!-- <b-alert v-if="!rows.length" show variant="warning" class="text-center m-2">
        <h5>Bilgi</h5>
        <hr />
        <p>Gösterilecek veri yok</p>
      </b-alert> -->

      <div class="p-2">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="false"
          :pagination-options="{
            enabled: true,
            perPage: 100,
            mode: 'pages',
          }"
          @on-selected-rows-change="selectionChanged"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionText: 'Satır Secildi.',
            clearSelectionText: 'Temizle',
          }"
          :search-options="{ enabled: false }"
          styleClass="vgt-table striped bordered"
        >
          <div slot="selected-row-actions">
            <b-button squared variant="danger" v-b-tooltip.hover="'Toplu Sil'" @click="handlerTopluSil">
              <i class="fad fa-trash" />
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'k_no'">
              <router-link
                :to="{
                  name: 'rezervasyon-detay',
                  params: { k_no: props.row.rez_k_no },
                }"
              >
                <span class="font-weight-bold d-block">
                  {{ props.row.rez_k_no }}
                </span>
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'tarih'">
              <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 12px">
                {{ props.row.save_date | momentFull }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'uye'" class="d-flex flex-column">
              <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 12px">
                {{ props.row.uye_ad }} {{ props.row.uye_soyad }}
              </b-badge>
              <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 12px">
                {{ props.row.uye_e_mail }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'banka'">
              <b-badge variant="primary" class="rounded-0 font-weight-light" style="font-size: 12px">
                {{ props.row.banka_adi }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'aciklama'">
              {{ props.row.aciklama }}
            </span>
            <span v-else-if="props.column.field === 'durum'">
              <b-badge
                :variant="props.row.statu ? 'success' : 'danger'"
                class="rounded-0 font-weight-light"
                style="font-size: 12px"
              >
                {{ props.row.statu ? 'Onaylandı' : 'Onaylanmadı' }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'fiyat'">
              <h5>
                <b-badge variant="success" class="rounded-0 font-weight-light d-block text-right">
                  {{ props.row.rezervasyon.rezervasyon_toplam.genel_toplam | cur }} {{ props.row.rezervasyon.sabit_kur }}
                </b-badge>
              </h5>
              <h5 v-if="props.row.rezervasyon.sabit_kur != props.row.rezervasyon.secilen_kur">
                <b-badge variant="info" class="rounded-0 font-weight-light d-block text-right">
                  {{ props.row.rezervasyon.kur_rezervasyon_toplam.genel_toplam | cur }}
                  {{ props.row.rezervasyon.secilen_kur }}
                </b-badge>
              </h5>
            </span>

            <span v-else-if="props.column.field === 'action'" class="float-right px-1">
              <b-button-group size="sm" class="pb-25">
                <b-button
                  class="rounded-0"
                  variant="warning"
                  v-b-tooltip.hover.left="'Rezervasyon Ödemesi Onayla'"
                  @click="handlerOdemeOnayla(props.row)"
                  v-if="!props.row.statu"
                >
                  <i class="fad fa-thumbs-up" />
                </b-button>
                <b-button
                  class="rounded-0"
                  variant="info"
                  v-b-tooltip.hover.left="'Rezervasyon Detay'"
                  :to="{
                    name: 'rezervasyon-detay',
                    params: { k_no: props.row.rez_k_no },
                  }"
                >
                  <i class="fad fa-eye" />
                </b-button>
                <b-button
                  variant="danger"
                  class="rounded-0"
                  v-b-tooltip.hover.left="'Sil'"
                  @click="handlerRemove(props.row.k_no)"
                >
                  <i class="fad fa-trash" />
                </b-button>
              </b-button-group>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom">
            <Pagination
              :urunler="rows"
              :count="count"
              @handlerPageSizeChange="handlerPageSizeChange($event)"
              @handlerPageChange="handlerPageChange($event)"
            />
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import { VueGoodTable } from 'vue-good-table';
import { ref, computed, defineComponent } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import Pagination from '../component/listele/Pagination.vue';
import { useRouter } from '@/libs/utils';
export default defineComponent({
  components: {
    VueGoodTable,
    Pagination,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    const { route } = useRouter();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.show = ref(false);
    expo.openAkModal = ref(false);
    expo.closeAkModal = ref(false);
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.multiSelectRow = ref([]);
    expo.updateData = ref([]);
    expo.updateAkData = ref({});
    expo.filter = ref({
      arama: null,
      page: 0,
      pageSize: 20,
    });

    expo.form = ref({
      k_no: null,
      rez_k_no: null,
      tahsilat: false,
    });
    expo.count = ref(0);

    expo.columns = ref([
      {
        label: 'Rezervasyon Numarası',
        field: 'k_no',
      },
      {
        label: 'Tarih',
        field: 'tarih',
      },
      {
        label: 'Uye',
        field: 'uye',
      },
      {
        label: 'Banka',
        field: 'banka',
      },
      {
        label: 'Açıklama',
        field: 'aciklama',
      },
      {
        label: 'Durum',
        field: 'durum',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'Fiyat',
        field: 'fiyat',
        width: '12%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '10%',
      },
    ]);

    expo.rows = ref([]);

    expo.handlerFetchAllData = async () => {
      context.emit('show', true);
      context.emit('pageTitle', 'Ödeme Bildirimler');

      route.value.query.page = route.value.query.page ? parseInt(route.value.query.page) : 1;
      route.value.query.page_size = route.value.query.page_size ? parseInt(route.value.query.page_size) : 20;

      await store.dispatch('rezervasyonOdemeBildirimListele', route.value.query).then((res) => {
        expo.rows.value = res.data.data;
        expo.count.value = res.data.count;
        context.emit('total', `${expo.count.value} kayıttan ${expo.rows.value.length} `);
        context.emit('show', false);
      });
    };
    expo.handlerFetchAllData();

    expo.selectionChanged = (event) => {
      expo.multiSelectRow.value = event.selectedRows;
    };

    expo.handlerOdemeOnayla = async (event) => {
      expo.form.value.rez_k_no = event.rez_k_no;
      expo.form.value.k_no = event.k_no;

      context.emit('show', true);
      Swal.fire({
        title: 'Uyarı',
        text: 'Ödeme Tahsilat Kaydı Yapılsın mı ? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          expo.form.value.tahsilat = true;
          store.dispatch('rezervasyonOdemeBildirimOnayla', expo.form.value).then((res) => {
            context.emit('show', false);
          });
        } else {
          expo.form.value.tahsilat = false;
          store.dispatch('rezervasyonOdemeBildirimOnayla', expo.form.value).then((res) => {
            context.emit('show', false);
          });
        }
      });
    };

    expo.handlerTopluSil = () => {};

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('rezervasyonOdemeBildirimSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    expo.handlerPageSizeChange = (event) => {
      router.push({
        query: { ...route.value.query, page_size: event },
      });
    };

    expo.handlerPageChange = (event) => {
      router.push({
        query: { ...route.value.query, page: event },
      });
    };

    return { ...expo };
  },
});
</script>
<style lang="scss" scoped></style>
